

































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { ProjectDocumentsActions, ProjectDocumentsMutations } from '@store/modules/project-documents/Types';
import { IComment } from '@store/modules/project-documents/Interfaces';

import './scss/PopupComments.scss';

const NSProjectDocuments = namespace('storeProjectDocuments');
const NSUser = namespace('storeUser');

@Component({
    name: 'PopupComments',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
    }
})

export default class PopupComments extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        textField: HTMLFormElement,
    }

    get disableBtn() {
        return this.btnDisabled || !this.comment.length;
    }

    @NSProjectDocuments.Action(ProjectDocumentsActions.A_SEND_NEW_COMMENT)
    sendNewComment!: (payload: { text: string; appId: string; documentId: string; }) => Promise<void>;

    @NSProjectDocuments.Mutation(ProjectDocumentsMutations.M_SET_COMMENTS) setComments!: (payload: IComment[]) => void;
    @NSProjectDocuments.Mutation(ProjectDocumentsMutations.M_SET_COMMENTS_LOADED) setCommentsLoaded!: (payload: boolean) => void;
    @NSProjectDocuments.Getter('comments') comments!: IComment[];
    @NSProjectDocuments.Getter('commentsLoaded') commentsLoaded!: boolean;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;

    comment: string = '';
    projectId: string = '';
    documentId: string = '';
    openedDocumentTitle: string = '';
    openedDocumentGroupTitle: string = '';
    btnDisabled: boolean = false;
    closingPopup: boolean = false;
    key: number = 0;

    openPopup() {
        this.closingPopup = false;
        this.$refs.basePopup.openPopup();
        if (this.$refs.textField) {
            this.$refs.textField.focusInput();
        }
    }

    closePopup() {
        this.$refs.basePopup.closePopup();
        this.onClose();
    }

    onClose() {
        this.setProjectId('');
        this.setDocumentId('');
        this.setDocumentTitle('');
        this.setComments([]);
        this.closingPopup = true;
        this.setCommentsLoaded(false);
    }

    setProjectId(val) {
        this.projectId = val;
    }

    setDocumentId(val) {
        this.documentId = val;
    }

    setDocumentTitle(val) {
        this.openedDocumentTitle = val;
    }

    setGroupTitle(val) {
        this.openedDocumentGroupTitle = val;
    }

    sendComment() {
        this.btnDisabled = true;
        this.sendNewComment({
            text: this.comment,
            appId: this.projectId,
            documentId: this.documentId,
        })
        this.comment = '';
        this.btnDisabled = false;
        this.key ++;
    }

    getDate(data) {
        const date = new Date(data);
        let day: string | number = date.getDate();
        let month: string | number = date.getMonth() + 1;
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return `${day}.${month}.${date.getFullYear()}`;
    }

    getTime(data) {
        const date = new Date(data);
        return `${date.getHours()}:${date.getMinutes()}`;
    }
}
